<template>
    <div class="popUpForm" v-loading="loading">
        <div class="content">
            <el-tabs v-model="activeName">
                <el-tab-pane name="form">
                    <span slot="label"><i class="iconfont iconbiaodan"></i>表单</span>
                    <formPage :form-data="code"></formPage>
                </el-tab-pane>
                <el-tab-pane
                    label="附件资料"
                    name="enclosure"
                    :disabled="isDisabled"
                >
                    <span slot="label"><i class="iconfont iconwenjianjia"></i>附件资料</span>
                    <annex
                        :form-id="formId"
                        :form-key-value="code.FormKeyValue"
                        :form-data="code"
                    ></annex>
                </el-tab-pane>
            </el-tabs>
        </div>
        <footer>
            <el-button
                type="primary"
                class="save"
                @click="save"
                :loading="saveBtnLoading"
                v-if="!isReadOnly"
            >
                保存
            </el-button>
            <el-button
                type="primary"
                class="save"
                @click="Printing"
                v-if="isDisabled == false"
            >
                打印
            </el-button>
            <el-button
                class="Close"
                @click="Close"
            >
                关闭
            </el-button>
        </footer>
    </div>
</template>

<script>
import formPage from '@/components/form/formPage';
import annex from './annex/index';
import store from '@/store';

export default {
    components: {
        formPage,
        annex,
    },
    props: {
        code: {
            type: [Array, Object, String, Function],
            required: true, // 是否必传
        },
    },
    data() {
        return {
            // 加载中状态
            loading: false,
            // 默认展开tab页
            activeName: 'form',
            // 附件状态
            isDisabled: true,
            // 当前表单ID
            formId: '',
            // 表单是否只读状态,判断保存按钮是否显示
            isReadOnly: false,
            // 当前表单唯一标识
            thisFormOnly: '',
            // 保存按钮状态
            saveBtnLoading: false,
            // 打开弹窗类型
            viewType: '',
        };
    },
    created() {
        if (this.code.FormUrl) {
            // 表单是否只读状态,判断保存按钮是否显示
            this.isReadOnly = this.$queryString(this.code.FormUrl, 'IsView');
            // 当前表单唯一标识赋值
            this.thisFormOnly = this.$queryString(this.code.FormUrl, 'FormCode');
        }
        if (this.code.FormKeyValue) {
            this.isDisabled = false;
            this.formId = this.code.FormKeyValue;
            // this.gitAnnexData(this.code.FormKeyValue);
        }
    },
    methods: {
    // 保存表单
        save() {
            this.saveBtnLoading = true;
            const iframe = window.document.getElementById('frameId' + this.thisFormOnly);
            iframe.contentWindow.JyFormSaveFormData(this.JTFlowAfterFormSaveSuccess, this.errorCallback);
        },
        // 保存表单成功回调
        JTFlowAfterFormSaveSuccess(PKValues) {
            this.saveBtnLoading = false;
            this.isDisabled = false;
            this.formId = PKValues.PKValues;

            this.Close();
            store.state.currentOpenList.forEach(item => {
                item.tableObj.updateTable();
            });
        },
        // 保存失败回调
        errorCallback() {
            this.saveBtnLoading = false;
        },
        // 取消添加关闭弹出层
        Close() {
            this.$parent.hide();
        },
        // 打印
        Printing() {
            const iframe = window.document.getElementById('frameId' + this.thisFormOnly);
            iframe.contentWindow.JyFormPrintForm();
        },
    },
};
</script>

<style lang="stylus"></style>
